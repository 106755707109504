import googleAnalytics from '@analytics/google-analytics';
import postHog from '@metro-fs/analytics-plugin-posthog';
import { Analytics, AnalyticsInstance as IAnalyticsInstance } from 'analytics';
import plausiblePlugin from 'analytics-plugin-plausible';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';

import { Nullable, WidgetType } from '@/types';

const SEEEN_MEASUREMENT_ID = GA_MEASUREMENT_ID; // injected in rollup

const GoogleAnalyticsContext = createContext<IAnalyticsInstance | undefined>(undefined);

interface GoogleAnalyticsProviderProps {
  clientTrackingId: Nullable<string>;
  clientMeasurementId: Nullable<string>;
  widgetType: WidgetType;
  children: ReactNode;
}

export function GoogleAnalyticsProvider({
  clientTrackingId,
  clientMeasurementId,
  widgetType,
  children,
}: GoogleAnalyticsProviderProps) {
  const measurementIds = [SEEEN_MEASUREMENT_ID, clientMeasurementId].filter(Boolean) as string[];
  const AnalyticsInstance = useMemo(() => {
    const googleAnalyticsPluginV4 = googleAnalytics({
      measurementIds,
    });

    const plausiblePluginInstance = plausiblePlugin({
      domain: 'seeen.com', // By default window.location.hostname
      // hashMode: true, // By default 'false'
      // trackLocalhost: true, // By default 'false'
      // apiHost: "https://my-plausible-api.domain.com/", // By default 'https://plausible.io'
    });

    const posthogPlugin = postHog({
      token: 'phc_cBP411kdDDml1o9oVGAf7dTlZmnDJGvKmjFWjSJLsOV',
      enabled: true,
      options: {
        api_host: 'https://us.i.posthog.com',
        debug: process.env.NODE_ENV === 'dev',
        persistence: 'memory',
        disable_cookie: true,
        disable_session_recording: true,
      },
    });

    return Analytics({
      app: 'Seeen Widget',
      debug: false,
      plugins: [googleAnalyticsPluginV4, plausiblePluginInstance, posthogPlugin],
    });
  }, [clientTrackingId, clientMeasurementId]);

  useEffect(() => {
    const event =
      widgetType === WidgetType.Carousel
        ? 'Initialize carousel widget'
        : 'Initialize player widget';
    AnalyticsInstance.track(event, {
      category: 'Micro moments Videos',
      label: document.title,
    });
  }, []);

  return (
    <GoogleAnalyticsContext.Provider value={AnalyticsInstance}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
}
type Props = { label?: string; videoId?: string; momentId?: string };
type TrackHandlerEx = (action: string, props?: Props) => void;
type TrackHandlerEx2 = (action: string, props?: { [key: string]: any }) => void;

type TrackHandler = (action: string, label?: string) => void;

export function useGoogleAnalyticsEx(category: string) {
  const analytics = useContext(GoogleAnalyticsContext);

  const track = useCallback<TrackHandlerEx>(
    (action, props = {}) => {
      // console.log('trackEx', action, props);
      // debugger;

      analytics?.track(action, {
        category,
        ...props,
      });
    },
    [category],
  );
  return track;
}

export function useGoogleAnalyticsEx2(category: string) {
  const analytics = useContext(GoogleAnalyticsContext);

  const track = useCallback<TrackHandlerEx2>(
    (action, props = {}) => {
      // console.log('trackEx2', action, props);
      // debugger;
      const analyticsProps = props;
      // round props.currentTime if present
      if (analyticsProps.currentTime) {
        analyticsProps.currentTime = Math.round(analyticsProps.currentTime);
      }

      // console.log('trackEx2', action, analyticsProps);

      analytics?.track(action, {
        category,
        ...analyticsProps,
      });
    },
    [category],
  );
  return track;
}

export function useGoogleAnalytics(category: string) {
  const analytics = useContext(GoogleAnalyticsContext);

  const track = useCallback<TrackHandler>(
    (action, label) => {
      // console.log('track', action);
      // debugger;

      analytics?.track(action, {
        category,
        label,
      });
    },
    [category],
  );
  return track;
}
